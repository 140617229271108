import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/ui/Container';
import * as Styled from './styles';

import { SectionTitle } from 'helpers/definitions';

import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';

interface Commerce extends SectionTitle {
  content: string;
  linkTo: string;
  linkText: string;
}

const Commerce: React.FC = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "commerce section" } }) {
        frontmatter {
          title
          subtitle
          content
          linkTo
          linkText
        }
      }
    }
  `);

  const privacy: Commerce = markdownRemark.frontmatter;

  return (
    <Container section>
      <div class="min-w-full bg-white max-w-2xl shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">特定商取引法（通信販売）に基づく表示</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Specified Commercial Transaction Law</p>
        </div>
        <div class="border-t border-gray-200">
          <div class="pt-10 sm:px-6 text-base leading-6 text-gray-900">
            当社で取り扱う商品・サービスのうち、当社が販売者の商品・サービスについて、「特定商取引に関する法律」第11条に基づき、次のとおり明示します。
          </div>
          <Styled.List>
            <Styled.Title>販売業者・サービス提供業者</Styled.Title>
            <Styled.TitleDescription>合同会社 CampoLungo</Styled.TitleDescription>

            <Styled.Title>運営責任者</Styled.Title>
            <Styled.TitleDescription>代表社員　長野 有紗</Styled.TitleDescription>

            <Styled.Title>所在地</Styled.Title>
            <Styled.TitleDescription>東京都日本橋兜町17-2</Styled.TitleDescription>
            <Styled.Title>お問い合わせ先</Styled.Title>
            <Styled.TitleDescription>
              <a href="/contact">弊社問い合わせ窓口</a>にご連絡ください。
            </Styled.TitleDescription>
            <Styled.Title>商品価格</Styled.Title>
            <Styled.TitleDescription>
              各商品・サービスのチラシ・Webページ上に掲載します。当社取扱のサービスは全て総額表示で記載しています。
            </Styled.TitleDescription>
            <Styled.Title>商品代金・サービス料金以外に購入者が負担すべき金銭</Styled.Title>
            <Styled.Subtitle>（1）送料</Styled.Subtitle>
            <Styled.Description>送料が必要な取引については事前に金額を明示します。</Styled.Description>

            <Styled.Subtitle>（2）支払手数料</Styled.Subtitle>
            <Styled.Description>代金引換手数料・振込手数料・発券手数料</Styled.Description>
            <Styled.Title>商品代金・サービス料金の支払方法と支払時期</Styled.Title>
            <Styled.TitleDescription>
              支払方法は、注文手続中に利用可能なものを画面に表示する方法、またはその他の方法により案内します。お客様は、各支払方法によって定まる支払時期までに支払うものとします。
            </Styled.TitleDescription>
            <Styled.Title>商品のお届け時期・サービスの提供時期</Styled.Title>
            <Styled.TitleDescription>
              ウェブサイト上に表示する等の方法により時期（期日、期間、目安等の場合も含む）を案内します。
            </Styled.TitleDescription>
            <Styled.Title>取消、払戻しについて</Styled.Title>
            <Styled.TitleDescription>
              お申し込み後の商品変更・ご注文の取消し、払戻しはできません。
            </Styled.TitleDescription>
          </Styled.List>
        </div>
      </div>
    </Container>
  );
};

export default Commerce;
